import * as CookieConsent from 'vanilla-cookieconsent';

window.CookieConsent = CookieConsent;
let domain = window.location.hostname;
['zuske.sk', 'zuskehlavna.eu'].forEach(domainName => {
    if (domain.includes(domainName)) {
        domain = domainName;
    }
})

window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('consent', 'default', {
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'wait_for_update': 500,
});
gtag('js', new Date());
gtag('config', 'G-2ZE5Y0X79J');

CookieConsent.run({
    revision: 3,

    cookie: {
        domain: domain,
        name: 'cc_cookie_consent'
    },
    
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,
                    }
                ]
            }
        }
    },

    language: {
        default: 'sk',
        autoDetect: 'document',
        translations: {
            sk: '/json/sk.json?ver=1.0.1',
            en: '/json/en.json?ver=1.0.1'
        }

    },
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom center',
            flipButtons: false,
            equalWeightButtons: true
        },
        preferencesModal: {
            layout: 'box',
            // position: 'left right',
            flipButtons: false,
            equalWeightButtons: true
        }
    },
    onFirstConsent: () => {
        CookieConsent.eraseCookies('cc_cookie');
    },
    onConsent: () => {
        if (CookieConsent.acceptedCategory('analytics')) {
            gtag('consent', 'update', {'analytics_storage' : 'granted'});
            if ('requestIdleCallback' in window) {
                requestIdleCallback(() => {
                    CookieConsent.loadScript('https://www.googletagmanager.com/gtag/js?id=G-2ZE5Y0X79J', { 'async': '' });
                })
            } else {
                CookieConsent.loadScript('https://www.googletagmanager.com/gtag/js?id=G-2ZE5Y0X79J', { 'async': '' });
            }
        } else {
            CookieConsent.eraseCookies(/^_ga/);
        }
    }
});